import { BaseImage } from "src/components/base/BaseImage";
import styles from "./ImagePreview.module.scss";
import { BaseIcon } from "src/components/base/BaseIcon";
import clsx from "clsx";

type PropsType = {
  className?: string;
  disabled?: boolean;
  onDelete: () => void;
  onEdit: () => void;
  src?: string | File;
  isBigImage?: boolean;
};

export default function ImagePreview({
  disabled,
  src,
  onDelete,
  onEdit,
  isBigImage,
}: PropsType) {
  return (
    <div
      className={clsx({
        [styles.ImagePreview]: true,
        [styles["ImagePreview--isBig"]]: isBigImage,
      })}
    >
      <BaseImage
        className={clsx(styles.ImagePreview__image, {
          [styles["ImagePreview--isBig"]]: isBigImage,
        })}
        src={src}
        width={!isBigImage ? 397 : 718}
        height={!isBigImage ? 183.89 : 240}
        lazyLoading={true}
      />
      <div
        className={clsx({
          [styles.ImagePreview__tools]: true,
          [styles.ImagePreview__tools_hide]: disabled,
        })}
      >
        <div
          className={styles.ImagePreview__pencil}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onEdit();
          }}
        >
          <BaseIcon
            className={styles.ImagePreview__pencil_icon}
            viewBox="0 0 24 24"
            height="24"
            width="24"
            icon="PENCIL"
          />
        </div>
        <div
          className={styles.ImagePreview__trash_bin}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onDelete();
          }}
        >
          <BaseIcon
            className={styles.ImagePreview__trash_bin_icon}
            viewBox="0 0 18 20"
            height="20"
            width="18"
            icon="TRASH_BIN"
          />
        </div>
      </div>
    </div>
  );
}
