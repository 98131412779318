export const gerCurrentCostPolitic = (
  costPolitic: [
    {
      id: string;
      name: string;
      price?: number;
    },
  ],
) => {
  //фильтруем costPolitic от пустых полей и defaultValue
  const filterCostPolitic = costPolitic.filter(
    (item) => item.name && item.price,
  );

  //проверяем  filterCostPolitic пуст ли и если пуст то ставим дефолт значения
  const currentCostPolitic: [
    {
      id: string;
      name: string;
      price?: number;
    },
  ] = !filterCostPolitic.length
    ? [{ name: "", price: 0, id: "" }]
    : (filterCostPolitic as [
        {
          id: string;
          name: string;
          price: number;
        },
      ]);

  return currentCostPolitic[0].name != ""
    ? { costPolitic: currentCostPolitic, isFree: false }
    : { isFree: true, costPolitic: currentCostPolitic };
};
