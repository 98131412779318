import React from "react";
import { Route, Routes } from "react-router-dom";

import { DefaultAccount } from "./layouts";
import { SignupRoutes } from "./pages/signup-routes";
import { SigninRoutes } from "./pages/signin-routes";
import { RoutePrivate } from "./components/protection/RoutePrivate";
import { Home } from "./pages/home";
import Signout from "./pages/signout";
import {
  MeetingsPage,
  AccountPage,
  EventsPage,
  EventUpdatePage,
  EventCreatePage,
  EventPage,
  EventAdminPage,
} from "./pages";

import { NavigatorExtendedType } from "./common/types/navigator.type";
import { cookieValue } from "./common/functions/cookie";
import { useAuth } from "./hooks/useAuth";
import { useCheckUser } from "./hooks/useCheckUser";

function App() {
  const { logoutUser } = useCheckUser();
  // const { signout } = useAuth();

  React.useEffect(() => {
    window.addEventListener("focus", logoutUser);
    return () => {
      window.removeEventListener("focus", logoutUser);
    };
    // if ("virtualKeyboard" in navigator) {
    //   (navigator as NavigatorExtendedType).virtualKeyboard.overlaysContent =
    //     true;
    // }

    // const handleSignout = async () => {
    //   const cookie = cookieValue();

    //   if (!("accessToken" in cookie)) {
    //     await signout(false);
    //   }
    // };

    // handleSignout();

    // addEventListener("focus", handleSignout);

    // return () => {
    //   removeEventListener("focus", handleSignout);
    // };
  }, []);

  return (
    <Routes>
      <Route path="/">
        <Route path="/" element={<Home />} />
        <Route element={<DefaultAccount />}>
          <Route path={"/event-list"} element={<EventsPage />} />
          <Route element={<RoutePrivate />}>
            <Route path="/update_event/:id" element={<EventUpdatePage />} />
            <Route path="/create_event" element={<EventCreatePage />} />
          </Route>
        </Route>
        <Route element={<DefaultAccount isWhiteHeader />}>
          <Route path={"/event-list/events/:id"} element={<EventPage />} />
          <Route
            path={"/event-list/adminEvents/:id"}
            element={<EventAdminPage />}
          />
        </Route>

        <Route element={<RoutePrivate />}>
          <Route element={<DefaultAccount />}>
            <Route path="meetings" element={<MeetingsPage />} />
            <Route path="account" element={<AccountPage />} />
          </Route>
        </Route>
        <Route path="signup/*" element={<SignupRoutes />} />
        <Route path="signin/*" element={<SigninRoutes />} />
        <Route path="signout" element={<Signout />} />
      </Route>
    </Routes>
  );
}

export default App;
