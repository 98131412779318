import styles from "../../layouts/CardsHome/CardsHome.module.scss";
type cardProps = {
  title: string;
  description: string;
  image?: string;
  link?: string;
  href?: string;
};
export const Card = (props: cardProps) => {
  return (
    <a
      className={styles.cardsHome__card}
      href={props.link ? props.link : ""}
      onClick={() => {
        if (!props.href) {
          return;
        }
        window.location.href = props.href;
      }}
    >
      <div className={styles.cardsHome__card_wrapper}>
        <h2 className={styles.cardsHome__title}>{props.title}</h2>
        <p className={styles.cardsHome__description}>{props.description}</p>
        <img
          src={props.image}
          alt="Preview"
          className={styles.cardsHome__image}
        />
      </div>
      <svg
        width="80.000000"
        height="80.000000"
        viewBox="0 0 80 80"
        fill="none"
        className={styles.cardsHome__next}
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs />
        <g id="fill" transform="translate(40, 40)">
          <circle className={styles.cardsHome__fill} r="0.1" />
        </g>
        <circle
          id="Ellipse 6"
          cx="40.000000"
          cy="40.000000"
          r="39.285713"
          stroke="#303030"
          strokeOpacity="1.000000"
          strokeWidth="1.428571"
        />
        <line
          id="Line 1"
          x1="21.890625"
          y1="39.979004"
          x2="57.605469"
          y2="39.979004"
          stroke="#303030"
          strokeOpacity="1.000000"
          strokeWidth="2.857142"
        />
        <path
          id="Vector"
          d="M42.39 23.06L58.1 38.78L58.1 41.21L42.39 56.93"
          stroke="#303030"
          strokeOpacity="1.000000"
          strokeWidth="2.857142"
        />
      </svg>
    </a>
  );
};
