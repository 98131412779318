import React from "react";

import { EventStatusEnum } from "src/common/enums";
import { NewEvent } from "src/api/hooks/eventsHooks";

type PropsType = NewEvent & {
  personIdFromJWT: string | undefined;
};

export function useEventConditions({ personIdFromJWT, ...rest }: PropsType) {
  // If places in paricipant list is free

  // If user is owner of event
  const isOwner: boolean = personIdFromJWT === rest.personId;
  // If event was completed
  const isComplited: boolean =
    rest.eventStatus?.name === EventStatusEnum["Завершено"];

  return {
    isOwner,
    isComplited,
  };
}
