// import { BaseMenu } from "src/components/base/BaseMenu";
// import { useAuth } from "src/hooks/useAuth";
import { Link } from "react-router-dom";

import { Logo } from "src/content/Logo/Logo";
import { BaseDropDown } from "../../base";

import { LINK_TO_AUTH_SHOP } from "../../../common/consts/shop";
import { QueriesEnum, RoutesEnum } from "src/common/enums";
import { useMe } from "src/api/hooks/personsHooks";
import styles from "./AccountHeader.module.scss";
import clsx from "clsx";

interface AccHeadType {
  isWhiteHeader?: boolean;
}
const AccountHeader: React.FC<AccHeadType> = ({ isWhiteHeader }) => {
  const { data } = useMe();

  return (
    <>
      <div className={styles.AccountHeader}>
        <Logo variant={isWhiteHeader ? "white" : "black"} />
        <nav className={styles.AccountHeader__navigation}>
          <ul className={styles.AccountHeader__list}>
            {links.map(({ to, href, text }, key) => (
              <li className={styles.AccountHeader__item} key={key}>
                {to && (
                  <Link
                    className={clsx(styles.AccountHeader__link, {
                      [styles["AccountHeader__me-white"]]: isWhiteHeader,
                    })}
                    to={to}
                  >
                    {text}
                  </Link>
                )}
                {href && (
                  <a
                    className={clsx(styles.AccountHeader__link, {
                      [styles["AccountHeader__me-white"]]: isWhiteHeader,
                    })}
                    href={href}
                  >
                    {text}
                  </a>
                )}
              </li>
            ))}
          </ul>
        </nav>
        {/*<ProfileSelect/>*/}
        <BaseDropDown list={userMenu} isWhiteHeader>
          <span
            className={clsx(styles.AccountHeader__me, {
              [styles["AccountHeader__me-white"]]: isWhiteHeader,
            })}
          >
            {data?.firstName ? data?.firstName : "Новый"}{" "}
            {data?.lastName ? data?.lastName : "Пользователь"}
          </span>
        </BaseDropDown>
      </div>
    </>
  );
};

const userMenu = [
  { label: "Мой профиль", href: "/account?tab=networking" },
  { label: "Мои покупки", href: "/account?tab=orders" },
  { label: "Мероприятия", href: RoutesEnum.EVENTS },
  { label: "Выход", href: "/signout" },
];

const links = [
  // {
  //   to: "/account",
  //   text: "Мой профиль",
  // },
  {
    to: "/meetings",
    text: "Встречи",
  },
  {
    to: RoutesEnum.EVENTS + QueriesEnum.ALL_EVENTS,
    text: "Мероприятия",
  },
  {
    //to: RoutesEnum.EVENTS + QueriesEnum.ALL_EVENTS,
    href: LINK_TO_AUTH_SHOP,
    text: "Магазин",
  },
  // {
  //   //to: RoutesEnum.EVENTS + QueriesEnum.ALL_EVENTS,
  //   href: LINK_TO_AUTH_SHOP,
  //   text: "Магазин",
  // },
];

export default AccountHeader;

// const ProfileSelect = () => {
//   const {authed} = useAuth();
//   const {data} = useMe();
//   const navigate = useNavigate();
//
//   return !authed || !data ? (
//     <></>
//   ) : (
//     <BaseMenu
//       options={options}
//       onChange={({value}) => {
//         navigate(value);
//       }}
//       value={`${!!data?.firstName ? data?.firstName : "Новый"} ${
//         !!data?.lastName ? data?.lastName : "пользователь"
//       }`}
//     />
//   );
// };

// const options = [
//   {
//     value: "/account",
//     label: "Мой профиль",
//   },
//   {
//     value: "/meetings",
//     label: "Встречи",
//   },
//   {
//     value: RoutesEnum.EVENTS + QueriesEnum.ALL_EVENTS,
//     label: "Мероприятия",
//   },
//   {
//     value: "/signout",
//     label: "Выйти",
//   },
// ];
