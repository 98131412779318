import clsx from "clsx";

import styles from "./Tag.module.scss";

type PropsType = React.HTMLAttributes<HTMLDivElement> & {
  className?: string;
  children: React.ReactNode;
  variant:
    | "event_preview"
    | "event_date"
    | "event_details"
    | "event_filter_date"
    | "event_filter_category"
    | "event_filter_category_clear";
  isActive?: boolean;
  isPrank?: boolean;
};

export default function Tag({
  className = "",
  children,
  variant,
  isActive = false,
  isPrank = false,
  ...rest
}: PropsType) {
  return (
    <div
      className={clsx({
        [styles.Tag]: true,
        [styles["Tag_" + variant]]: true,
        [styles["Tag_" + variant + "_active"]]: isActive && !isPrank,
        [styles.Tag_event_filter_prank]: isPrank,
        [styles.Tag_event_filter_prank_active]: isPrank && isActive,
        [className]: !!className,
      })}
      {...rest}
    >
      <span>{children}</span>
    </div>
  );
}
