import React from "react";
import { DeepRequired, FieldErrorsImpl } from "react-hook-form";

import { BaseDatePicker } from "src/components/base";
import { BaseInputError } from "src/components/base/BaseInputError";
import { BaseTypography } from "src/components/base/BaseTypography";
import { ImageUploader } from "../ImageUploader";
import { BaseTextarea } from "src/components/base/BaseTextarea";
import { ImagePreview } from "../ImagePreview";
import { SelectTags } from "../SelectTags";
import { BaseSwitch } from "src/components/base/BaseSwitch";
import { BaseInput } from "src/components/base/BaseInput";
import { BaseLabel } from "src/components/base/BaseLabel";
import SelectContained from "../SelectContained/SelectContained";

import {
  NewEventData,
  NewEventLink,
  NewEventType,
  useEventTypes,
} from "src/api/hooks/eventsHooks";
import { OptionType } from "src/common/types/option-type";
import styles from "./EventForm.module.scss";
import clsx from "clsx";

type PropsType = {
  className?: string;
  onHandleState: (
    key: keyof NewEventData,
    value: string | NewEventLink[] | NewEventType[] | boolean,
  ) => void;
  onUploadCover: (file: File | string) => void;
  onDeleteCover: () => void;
  onEditCover?: () => void;
  croppedCover: File | string | undefined;
  onUploadCoverDetails: (file: File | string) => void;
  onDeleteCoverDetails: () => void;
  onEditCoverDetails?: () => void;
  croppedCoverDetails: File | string | undefined;
  errors: FieldErrorsImpl<DeepRequired<NewEventData>>;
  state: NewEventData;
  disabled?: boolean;
};

const EventForm = ({
  className = "",
  onHandleState,
  onUploadCover,
  onDeleteCover,
  onEditCover,
  croppedCover,
  onUploadCoverDetails,
  onDeleteCoverDetails,
  onEditCoverDetails,
  croppedCoverDetails,
  errors,
  state,
  disabled,
}: PropsType) => {
  const { data: eventTypes } = useEventTypes();
  const timeOptions = useMakeTimeOptions();
  const foundTime = React.useMemo(() => {
    return timeOptions.find(({ name }) => state.time === name);
  }, [state.time]);

  const handleKeyPress =
    (maxLenght: number) => (e: React.ChangeEvent<HTMLInputElement> | any) => {
      if (!/[0-9]/.test(e.key) || e.currentTarget.value.length >= maxLenght) {
        e.preventDefault();
      }
    };

  const onHandleStateLinks = (
    key: "name" | "link",
    value: string,
    index: number,
  ) => {
    const updatedLinks = [...state.eventsLink];
    updatedLinks[index] = { ...updatedLinks[index], [key]: value };
    onHandleState("eventsLink", updatedLinks);
  };

  const onHandleTicketsFields = (
    key: "name" | "price",
    value: string | number | undefined,
    index: number,
  ) => {
    console.log(value);
    const updatedTickets = [...state.costPolitic];
    updatedTickets[index] = { ...updatedTickets[index], [key]: value };
    onHandleState("costPolitic", updatedTickets);
  };

  const ActionTicketsFields = (isPlus: boolean, removeIndex?: number) => {
    const updatedLinks = [...state.costPolitic];

    if (isPlus && updatedLinks.length < 5) {
      updatedLinks.push({ id: `${Date.now()}`, name: "" });
    } else if (removeIndex !== undefined && updatedLinks.length > 1) {
      updatedLinks.splice(removeIndex, 1);
    }

    onHandleState("costPolitic", updatedLinks);
  };

  return (
    <>
      <ul className={[styles.EventForm, className].join(" ").trim()}>
        <h3 className={styles.EventForm__title}>Информация</h3>
        <li className={styles.EventForm__item}>
          <BaseLabel className={styles.EventForm__label} label="Организатор">
            <BaseInput
              placeholder="Название"
              onChange={(e) => onHandleState("organizer", e.target.value)}
              value={state.organizer}
              maxLength={80}
              disabled={disabled}
            />
            <InputCounter
              className={styles.EventForm__counter}
              from={state?.organizer?.length}
              till={80}
            />
          </BaseLabel>
          <BaseInputError field="organizer" errors={errors} />
        </li>
        <li className={styles.EventForm__item}>
          <BaseLabel
            className={styles.EventForm__label}
            label="Название мероприятия"
          >
            <BaseInput
              placeholder="Название"
              onChange={(e) => onHandleState("title", e.target.value)}
              value={state.title}
              disabled={disabled}
              maxLength={80}
            />
            <InputCounter
              className={styles.EventForm__counter}
              from={state?.title?.length}
              till={80}
            />
          </BaseLabel>
          <BaseInputError field="title" errors={errors} />
        </li>
        <li className={styles.EventForm__item}>
          <BaseLabel className={styles.EventForm__label} label="Описание">
            <BaseTextarea
              className={styles.EventForm__textarea}
              placeholder="Опишите цели встречи"
              onChange={(e) => onHandleState("text", e.target.value)}
              value={state.text}
              disabled={disabled}
              maxLength={2000}
            />
            <InputCounter
              className={styles.EventForm__counter}
              from={state?.text?.length}
              till={2000}
            />
          </BaseLabel>
          <BaseInputError field="text" errors={errors} />
        </li>
        <li className={styles.EventForm__item}>
          <BaseLabel label="Фото для превью">
            {croppedCover ? (
              <ImagePreview
                onDelete={() => onDeleteCover()}
                disabled={disabled}
                onEdit={() => onEditCover && onEditCover()}
                src={croppedCover}
              />
            ) : (
              <ImageUploader onChange={(file) => onUploadCover(file)} />
            )}
          </BaseLabel>
        </li>
        <li className={styles.EventForm__item}>
          <BaseLabel label="Фото для деталей мероприятия">
            {croppedCoverDetails ? (
              <ImagePreview
                onDelete={() => onDeleteCoverDetails()}
                disabled={disabled}
                onEdit={() => onEditCoverDetails && onEditCoverDetails()}
                src={croppedCoverDetails}
                isBigImage
              />
            ) : (
              <ImageUploader
                onChange={(file) => onUploadCoverDetails(file)}
                isBigImage
              />
            )}
          </BaseLabel>
        </li>
        <li className={styles.EventForm__item}>
          <BaseLabel label="Тематика и теги поиска">
            <SelectTags
              placeholder="Выберите"
              onChange={(options) => onHandleState("eventType", options)}
              options={eventTypes as OptionType[]}
              value={state.eventType as unknown as OptionType[]}
              maxLenght={3}
              disabled={disabled}
            />
          </BaseLabel>
          <BaseInputError field="eventType" errors={errors} />
        </li>
        <li
          className={clsx(styles.EventForm__item, styles.EventForm__itemTwoRow)}
        >
          <div>
            <BaseLabel label="Дата проведения">
              <BaseDatePicker
                onChange={(value: string | number | OptionType) => {
                  if (typeof value === "string") {
                    onHandleState("meetingAt", value);
                  }
                }}
                value={state.meetingAt}
                disabled={disabled}
              />
            </BaseLabel>
            <BaseInputError field="meetingAt" errors={errors} />
          </div>
          <div>
            <BaseLabel label="Время проведения">
              <SelectContained
                placeholder="Выберите"
                multiple={false}
                onChange={({ name }) => onHandleState("time", name)}
                value={foundTime}
                options={timeOptions}
              />
            </BaseLabel>
            <BaseInputError field="meetingAt" errors={errors} />
          </div>
        </li>
        <li className={styles.EventForm__item}>
          <BaseLabel label="Место проведения">
            <BaseInput
              placeholder="Введите адрес"
              onChange={(e) => onHandleState("address", e.target.value)}
              value={state.address}
              disabled={disabled}
            />
          </BaseLabel>
          <BaseInputError field="address" errors={errors} />
        </li>

        <li className={styles.EventForm__item}>
          <BaseLabel
            className={styles.EventForm__label_cost}
            label="Стоимость мероприятия, руб."
            htmlFor=""
          >
            <BaseSwitch
              className={styles.EventForm__switch}
              checked={state.isFree}
              onChange={(e) => onHandleState("isFree", e.target.checked)}
              label="Бесплатное"
            />
            <div
              className={clsx(styles.EventForm__input_cost_wrapper, {
                [styles.EventForm__input_hide]: state.isFree,
              })}
            >
              <div className={styles.EventForm__costList}>
                {state.costPolitic.map((item, j) => {
                  return (
                    <li
                      className={clsx(styles.EventForm__itemCoast)}
                      key={item.id}
                    >
                      <div className={styles.EventForm__itemCoast_name}>
                        <BaseInput
                          placeholder="Название билета"
                          numberOfInput={j + 1}
                          onChange={(e) =>
                            onHandleTicketsFields("name", e.target.value, j)
                          }
                          value={item.name}
                          maxLength={30}
                        />
                        <BaseInputError
                          children={
                            item.price && !item.name
                              ? "Это обязательное поле"
                              : undefined
                          }
                        />
                      </div>
                      <div className={styles.EventForm__itemCoast_price}>
                        <BaseInput
                          placeholder="Стоимость, ₽"
                          onChange={(e) =>
                            onHandleTicketsFields(
                              "price",
                              !isNaN(parseFloat(e.target.value))
                                ? parseFloat(e.target.value)
                                : undefined,
                              j,
                            )
                          }
                          value={item.price ?? ""}
                        />
                        <BaseInputError
                          children={
                            item.price != undefined && item.price <= 0
                              ? "Цена должна быть больше 0"
                              : !item.price && item.name
                              ? "Это обязательное поле"
                              : undefined
                          }
                        />
                      </div>

                      <button
                        type="button"
                        disabled={state.costPolitic.length <= 1}
                        className={clsx(
                          styles.EventForm__itemCoastButton,
                          styles.EventForm__itemCoast_trash,
                        )}
                        onClick={() => ActionTicketsFields(false, j)}
                      >
                        <TrashIcon />
                      </button>
                    </li>
                  );
                })}
              </div>
              <button
                type="button"
                className={clsx(
                  styles.EventForm__itemCoastButton,
                  styles.EventForm__itemCoastButton_plus,
                  {
                    [styles.EventForm__itemCoastButton_hide]:
                      state.costPolitic.length >= 5,
                  },
                )}
                onClick={() => {
                  ActionTicketsFields(true);
                }}
              >
                <span>Добавить билет</span>
              </button>
            </div>
          </BaseLabel>
        </li>

        <h3 className={styles.EventForm__title}>Ссылки</h3>
        <li className={styles.EventForm__item}>
          <BaseLabel label="Ссылка на чат мероприятия">
            <BaseInput
              placeholder="Введите ссылку"
              onChange={(e) => onHandleState("eventLink", e.target.value)}
              value={state.eventLink}
              disabled={disabled}
              maxLength={100}
            />
          </BaseLabel>
          <BaseInputError field="eventLink" errors={errors} />
        </li>
        <li className={styles.EventForm__item}>
          <BaseLabel label="Ссылка на регистрацию">
            <BaseInput
              placeholder="Введите ссылку"
              onChange={(e) =>
                onHandleState("registrationLink", e.target.value)
              }
              value={state.registrationLink}
              disabled={disabled}
              maxLength={100}
            />
          </BaseLabel>
          <BaseInputError field="registrationLink" errors={errors} />
        </li>
        <li className={styles.EventForm__item}>
          <BaseLabel label="Ссылки связанные с мероприятием">
            <div className={clsx(styles.EventForm__itemSiteLinks)}>
              {state.eventsLink.map((item, j) => {
                return (
                  <>
                    <div>
                      <BaseInput
                        placeholder="Название ссылки"
                        numberOfInput={j + 1}
                        onChange={(e) =>
                          onHandleStateLinks("name", e.target.value, j)
                        }
                        value={item.name}
                      />
                      <BaseInputError
                        children={
                          errors.eventsLink
                            ? j == 0 && !!errors.eventsLink.message
                              ? errors.eventsLink.message
                              : errors.eventsLink[j]?.name?.message
                            : undefined
                        }
                      />
                    </div>
                    <div>
                      <BaseInput
                        placeholder="Адрес ссылки"
                        onChange={(e) =>
                          onHandleStateLinks("link", e.target.value, j)
                        }
                        value={item.link}
                      />
                      <BaseInputError
                        children={
                          errors.eventsLink
                            ? j == 0 && !!errors.eventsLink.message
                              ? errors.eventsLink.message
                              : errors.eventsLink[j]?.link?.message
                            : undefined
                        }
                      />
                    </div>
                  </>
                );
              })}
            </div>
          </BaseLabel>
        </li>
        <li //
          className={clsx(styles.EventForm__item, styles.EventForm__itemTwoRow)}
        >
          <div>
            <BaseLabel label="Ссылка на билет">
              <BaseInput
                placeholder="Введите ссылку"
                temporarilyDisabled
                // onChange={(e) => onHandleState("ticketUrl", e.target.value)}
                // value={state.ticketUrl}
              />
            </BaseLabel>
          </div>
          <div>
            <BaseLabel label="Артикул билета">
              <BaseInput
                placeholder="Введите артикул"
                temporarilyDisabled
                // onChange={(e) => onHandleState("ticketUrl", e.target.value)}
                // value={state.ticketUrl}
              />
            </BaseLabel>
          </div>
        </li>
        <li
          className={clsx(styles.EventForm__item, styles.EventForm__itemTwoRow)}
        >
          <div>
            <BaseLabel label="Ссылка на закрытое сообщество">
              <BaseInput
                placeholder="groupname.terkatalk.ru"
                temporarilyDisabled
                // onChange={(e) => onHandleState("ticketUrl", e.target.value)}
                // value={state.ticketUrl}
              />
            </BaseLabel>
          </div>
          <div>
            <BaseLabel label="Ссылка на закрытый tg-канал">
              <BaseInput
                placeholder="@name_terkatalk"
                temporarilyDisabled
                // onChange={(e) => onHandleState("ticketUrl", e.target.value)}
                // value={state.ticketUrl}
              />
            </BaseLabel>
          </div>
        </li>
        <li className={styles.EventForm__item}>
          <BaseLabel label="ИИ-ассистент">
            <BaseInput
              placeholder="Введите через запятую параметры"
              temporarilyDisabled
            />
          </BaseLabel>
        </li>
        <li className={styles.EventForm__item}>
          <BaseLabel label="Тэги партнеров системы лояльности">
            <BaseInput
              placeholder="Введите через запятую тэги terkaloyal"
              temporarilyDisabled
            />
          </BaseLabel>
        </li>
        <li className={styles.EventForm__item}>
          <BaseLabel label="Email (для получения статистики и аналитики)">
            <BaseInput placeholder="Напишите email" temporarilyDisabled />
          </BaseLabel>
        </li>
      </ul>
    </>
  );
};

const useMakeTimeOptions = () => {
  const result = React.useMemo(() => {
    const result = [];
    const minute = {
      start: 0,
      step: 30,
      end: 50,
    };
    const hour = {
      start: 0,
      step: 1,
      end: 23,
    };
    for (let i = hour.start; i <= hour.end; i += hour.step) {
      for (let j = minute.start; j <= minute.end; j += minute.step) {
        result.push({
          id: Math.random().toString(16).slice(2, 6),
          name: (i < 10 ? "0" + i : i) + ":" + (j < 10 ? "0" + j : j),
        });
      }
    }

    return result;
  }, []);

  return result;
};

type InputCounterPropsType = {
  className?: string;
  from?: number;
  till?: number;
};

const InputCounter = React.memo(
  ({ className = "", from = 0, till = 0 }: InputCounterPropsType) => {
    return (
      <>
        <BaseTypography
          className={clsx([styles.InputCounter, className])}
          variant="p"
        >
          <span
            className={clsx({
              [styles.InputCounter__from]: true,
              [styles.InputCounter__from_active]: from > 0,
            })}
            style={from > till ? { color: "rgba(233, 0, 0, 0.5)" } : {}}
          >
            {from}
          </span>
          <span className={styles.InputCounter__divider}>{"/"}</span>
          <span
            className={clsx({
              [styles.InputCounter__till]: true,
              [styles.InputCounter__till_active]: from > 0,
            })}
          >
            {till}
          </span>
        </BaseTypography>
      </>
    );
  },
);

const MemoizedEventForm = React.memo(EventForm);

export default MemoizedEventForm;

const TrashIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.512 8.25L13.1947 16.5M8.80529 16.5L8.48798 8.25M17.6253 5.30802C17.9388 5.35536 18.2512 5.40601 18.5625 5.45993M17.6253 5.30802L16.6465 18.0332C16.5638 19.1077 15.6678 19.9375 14.5901 19.9375H7.40994C6.33221 19.9375 5.43617 19.1077 5.35351 18.0332L4.37466 5.30802M17.6253 5.30802C16.5745 5.14932 15.5114 5.02778 14.4375 4.9448M3.4375 5.45993C3.74878 5.40601 4.06117 5.35536 4.37466 5.30802M4.37466 5.30802C5.42554 5.14932 6.48862 5.02778 7.5625 4.9448M14.4375 4.9448V4.105C14.4375 3.02392 13.6027 2.12138 12.5222 2.08681C12.0168 2.07065 11.5093 2.0625 11 2.0625C10.4907 2.0625 9.98321 2.07065 9.4778 2.08681C8.39727 2.12138 7.5625 3.02392 7.5625 4.105V4.9448M14.4375 4.9448C13.3032 4.85714 12.1568 4.8125 11 4.8125C9.8432 4.8125 8.69682 4.85714 7.5625 4.9448"
        stroke="#FF3737"
        stroke-opacity="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
