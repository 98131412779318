import React, { useState, useRef, useEffect } from "react";

import s from "./Dropdown.module.scss";

import clsx from "clsx";

interface ContentType {
  text: string;
}

const ContentDropdown = ({ text }: ContentType) => {
  const [isOpen, setIsOpen] = useState(false);

  const [isHeight120Plus, setIsHeight120Plus] = useState(false);

  const contentRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);

  const toggleItem = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleHeightChange = () => {
      if (contentRef.current && textRef.current) {
        if (textRef.current.offsetHeight > 120) {
          setIsHeight120Plus(true);
          contentRef.current.style.height = `${textRef.current.scrollHeight}px`;
          contentRef.current.style.height = isOpen
            ? `${textRef.current.scrollHeight}px`
            : "121px";
        } else {
          setIsHeight120Plus(false);
          setIsOpen(false);
          contentRef.current.style.height = `${textRef.current.offsetHeight}px`;
        }
      }
    };
    handleHeightChange();
    window.addEventListener("resize", handleHeightChange);
    return () => window.removeEventListener("resize", handleHeightChange);
  }, [isOpen, contentRef]);

  return (
    <div>
      <div
        ref={contentRef}
        className={clsx(s.MainContent__AccordeonTextContainer, {
          [s.MainContent__AccordeonTextContainer__Open]: isOpen,
        })}
      >
        <p className={s.MainContent__AccordeonText} ref={textRef}>
          {text}
        </p>
      </div>
      {isHeight120Plus && (
        <button
          type="button"
          onClick={toggleItem}
          className={s.MainContent__Button}
        >
          Читать полностью
        </button>
      )}
    </div>
  );
};

export default ContentDropdown;
