import ReactCrop, { Crop, PixelCrop } from "react-image-crop";
import React, { useEffect } from "react";

import { BaseTypography } from "src/components/base/BaseTypography";
import { BaseModal } from "src/components/base/BaseModal";
import { Button } from "src/components/base/Button";

import { useDebounceEffect } from "./useDebounceEffect";
import { centerAspectCrop } from "./centerAspectCrop";
import { canvasPreview } from "./canvasPreview";
import { imgPreview } from "./imgPreview";
import styles from "./Cropper.module.scss";
import "react-image-crop/src/ReactCrop.scss";

type PropsType = {
  isOpen: boolean;
  toggle: () => void;
  file: File;
  onChange: (croppedImage: File) => void;
  onCancel: () => void;
  type: "Cover" | "Details";
};

const Cropper = ({
  isOpen,
  file,
  onChange,
  onCancel,
  toggle,
  type = "Cover",
}: PropsType) => {
  // Options
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);

  const [aspect] = React.useState<number | undefined>(
    type === "Cover" ? 1.62 : 2.99,
  ); //16 / 7
  const [rotate] = React.useState(0);
  const [scale] = React.useState(1);

  // Uploaded file
  const [uploadedFile, setUploadedFile] = React.useState<File | undefined>();

  const [completedCrop, setCompletedCrop] = React.useState<PixelCrop>();
  const [imgBlob, setImgBlob] = React.useState();
  const [imgSrc, setImgSrc] = React.useState("");
  const [crop, setCrop] = React.useState<Crop>();

  // Refs
  const previewCanvasRef = React.useRef<HTMLCanvasElement | null>(null);
  const imgRef = React.useRef<HTMLImageElement | null>(null);

  // Set uploaded file to state
  React.useEffect(() => {
    if (!file) return;
    if (file || isOpen) {
      onSelectFile(file);
      setUploadedFile(file);
    }
  }, [file, isOpen]);

  function onSelectFile(file: File) {
    // Makes crop preview update between images.
    setCrop(undefined);

    const reader = new FileReader();
    reader.addEventListener("load", () =>
      setImgSrc(reader.result?.toString() || ""),
    );
    reader.readAsDataURL(file);
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  function onSaveHandler(imgBlob: Blob | undefined, uploadedFile: File) {
    if (imgBlob && uploadedFile) {
      onChange(new File([imgBlob], uploadedFile.name));
      setUploadedFile(undefined);
      previewCanvasRef.current = null;
      imgRef.current = null;
      toggle();
    }
  }

  function onCanclerHandler() {
    setUploadedFile(undefined);
    previewCanvasRef.current = null;
    imgRef.current = null;
    onCancel();
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        );
      }

      // Get blob from preview
      const { blob } = (await imgPreview(
        imgRef.current as any,
        completedCrop as any,
        scale,
        rotate,
      )) as any;

      // Set blob to state
      setImgBlob(blob);
    },
    100,
    [completedCrop, scale, rotate],
  );

  return (
    <>
      <BaseModal
        isShowing={isOpen}
        toggle={onCanclerHandler}
        title="Редактировать фото"
      >
        <div className={styles.CropperModal}>
          <div className={styles.CropperModal__header}>
            <BaseTypography
              className={styles.CropperModal__subtitle}
              variant="h3"
            >
              Настройте размер фотографии
            </BaseTypography>
          </div>
          <div className={styles.CropperModal__body}>
            <div className={styles.CropperModal__crop}>
              {!!imgSrc && (
                <ReactCrop
                  onChange={(_, percentCrop) => setCrop(percentCrop)}
                  onComplete={(c) => setCompletedCrop(c)}
                  aspect={aspect}
                  crop={crop}
                >
                  <img
                    onLoad={onImageLoad}
                    ref={imgRef}
                    src={imgSrc}
                    alt="Обрезать фото"
                  />
                </ReactCrop>
              )}
            </div>
            <div className={styles.CropperModal__buttons}>
              <Button
                className={styles.CropperModal__button}
                onClick={() =>
                  uploadedFile && onSaveHandler(imgBlob, uploadedFile)
                }
                variant="contained_nuar"
              >
                Сохранить
              </Button>
            </div>
          </div>
        </div>
      </BaseModal>
    </>
  );
};

const MemoizedCropper = React.memo(Cropper);

export default MemoizedCropper;

//New ImageCropper
// import React from "react";

// import { BaseTypography } from "src/components/base/BaseTypography";
// import { BaseModal } from "src/components/base/BaseModal";
// import { Button } from "src/components/base/Button";

// import styles from "./Cropper.module.scss";
// import "react-image-crop/src/ReactCrop.scss";
// import { ImageCropper, ImageCropperRef } from "react-optimizer-image-cropper";

// type PropsType = {
//   isOpen: boolean;
//   toggle: () => void;
//   file: File;
//   onChange: (croppedImage: File) => void;
//   onCancel: () => void;
//   type: "Cover" | "Details";
// };

// const blobToFile = (theBlob: Blob, fileName: string): File => {
//   return new File([theBlob as any], fileName, {
//     lastModified: new Date().getTime(),
//     type: theBlob.type,
//   });
// };

// const Cropper = ({
//   isOpen,
//   file,
//   onChange,
//   onCancel,
//   toggle,
//   type = "Cover",
// }: PropsType) => {
//   // Options
//   const [aspect] = React.useState<number | undefined>(
//     type === "Cover" ? 1.62 : 2.99,
//   );
//   const [rotate] = React.useState(0);
//   const [scale] = React.useState(1);

//   const [imgSrc, setImgSrc] = React.useState("");

//   const previewCanvasRef = React.useRef<HTMLCanvasElement | null>(null);
//   const imgRef = React.useRef<ImageCropperRef | null>(null);

//   // Set uploaded file to state
//   React.useEffect(() => {
//     if (!file) return;
//     if (file || isOpen) {
//       onSelectFile(file);
//     }
//   }, [file, isOpen]);

//   function onSelectFile(file: File) {
//     const reader = new FileReader();
//     reader.addEventListener("load", () =>
//       setImgSrc(reader.result?.toString() || ""),
//     );
//     reader.readAsDataURL(file);
//   }

//   async function onSaveHandler() {
//     if (!imgRef.current) return;

//     const blob = await imgRef.current.returnResult("webp");

//     if (blob) {
//       onChange(blobToFile(blob, file.name));
//       previewCanvasRef.current = null;
//       imgRef.current = null;
//       toggle();
//     }

//     setImgSrc("");
//   }

//   function onCanclerHandler() {
//     previewCanvasRef.current = null;
//     imgRef.current = null;
//     toggle();
//   }

//   return (
//     <>
//       <BaseModal
//         isShowing={isOpen}
//         toggle={onCanclerHandler}
//         title="Редактировать фото"
//       >
//         <div className={styles.CropperModal}>
//           <div className={styles.CropperModal__header}>
//             <BaseTypography
//               className={styles.CropperModal__subtitle}
//               variant="h3"
//             >
//               Настройте размер фотографии
//             </BaseTypography>
//           </div>
//           <div className={styles.CropperModal__body}>
//             <div className={styles.CropperModal__crop}>
//               {!!imgSrc && (
//                 <ImageCropper
//                   ref={imgRef}
//                   src={file}
//                   aspect={aspect}
//                   loader={<span></span>}
//                   scale={scale}
//                   rotate={rotate}
//                   optimized={true}
//                 />
//               )}
//             </div>
//             <div className={styles.CropperModal__buttons}>
//               <Button
//                 className={styles.CropperModal__button}
//                 onClick={() => onSaveHandler()}
//                 variant="contained_nuar"
//               >
//                 Сохранить
//               </Button>
//             </div>
//           </div>
//         </div>
//       </BaseModal>
//     </>
//   );
// };

// const MemoizedCropper = React.memo(Cropper);

// export default MemoizedCropper;
