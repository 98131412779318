import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast/headless";

import { Cropper, EventForm, Preloader } from "src/content";
import { LayoutAccount } from "src/layouts";
import { BaseButton } from "src/components/base/BaseButton";
import { BaseImage } from "src/components/base/BaseImage";
import { Button } from "src/components/base/Button";

import {
  NewEventData,
  NewEventLink,
  NewEventType,
  useEventByAlias,
  useEventStatuses,
  useUpdateEvent,
  useUploadImage,
} from "src/api/hooks/eventsHooks";
import { EventScheme } from "src/common/validation";
import { CloseEventModal } from "src/modals/CloseEventModal";
import { useModal } from "src/hooks/useModal";
import imageGopnikCreateEvent from "src/common/images/image-gopnik-create-event.webp";
import styles from "../event-create/EventCreate.module.scss";
import useUploadCover from "src/hooks/useUploadCover";
import { gerCurrentCostPolitic } from "src/api/hooks/getCurrentCostPolitic";

const EventUpdatePage = () => {
  const params = useParams();
  const id = params?.id ? params.id : "";

  const [isLoadingData, setIsLoadinData] = useState(true);
  const { data: event, isLoading: isLoadingEvent } = useEventByAlias({ id });

  const navigate = useNavigate();
  const {
    uploadedCover,
    croppedCover,
    handleDeleteCropped,
    handleEditCover,
    handleUploadCover,
    handleUploadCropped,
    isOpenCropper,
    toggleCropper,
  } = useUploadCover();

  const {
    uploadedCover: uploadedCoverDetils,
    croppedCover: croppedCoverDetils,
    handleDeleteCropped: handleDeleteCroppedDetils,
    handleEditCover: handleEditCoverDetils,
    handleUploadCover: handleUploadCoverDetils,
    handleUploadCropped: handleUploadCroppedDetils,
    isOpenCropper: isOpenCropperDetils,
    toggleCropper: toggleCropperDetils,
  } = useUploadCover();

  // Form initialize
  const form = useForm<NewEventData>({
    defaultValues: {
      title: "",
      text: "",
      address: "",
      eventsLink: [
        { name: "", link: "" },
        { name: "", link: "" },
        { name: "", link: "" },
      ],
      isFree: false,
      eventLink: "",
      costPolitic: [
        {
          id: `${new Date()}`,
          name: "",
          price: undefined,
        },
      ],
    },
    resolver: yupResolver(EventScheme),
  });

  React.useEffect(() => {
    if (!event) return;

    for (const key in event) {
      form.setValue(
        key as keyof NewEventData,
        !!(event as NewEventData)[key as keyof NewEventData]
          ? (event as NewEventData)[key as keyof NewEventData]
          : form.getValues(key as keyof NewEventData),
      );
    }

    if (
      !croppedCoverDetils &&
      event.multimedia?.find((imageObg) => imageObg.type === "Details")
    ) {
      fetch(
        event.multimedia?.find((imageObg) => imageObg.type === "Details")!.path,
      )
        .then((res) => res.blob())
        .then((blob) => {
          if (
            event.multimedia?.find((imageObg) => imageObg.type === "Details")
              ?.fileName
          ) {
            const file = new File(
              [blob],
              event.multimedia?.find(
                (imageObg) => imageObg.type === "Details",
              )!.fileName,
              blob,
            );
            handleUploadCoverDetils(file, false);
            handleUploadCroppedDetils(file);
          }
        })
        .catch((e) => {
          console.log("FETCH EVENT IMAGE ERROR: ", e);
        });
    }

    if (
      !croppedCover &&
      event.multimedia?.find((imageObg) => imageObg.type === "Cover")
    ) {
      fetch(
        event.multimedia?.find((imageObg) => imageObg.type === "Cover")!.path,
      )
        .then((res) => res.blob())
        .then((blob) => {
          if (
            event.multimedia?.find((imageObg) => imageObg.type === "Cover")
              ?.fileName
          ) {
            const file = new File(
              [blob],
              event.multimedia?.find(
                (imageObg) => imageObg.type === "Cover",
              )!.fileName,
              blob,
            );
            handleUploadCover(file, false);
            handleUploadCropped(file);
          }
        })
        .catch((e) => {
          console.error("FETCH EVENT IMAGE ERROR: ", e);
        });
    }
    setIsLoadinData(false);
  }, [event]);

  // Form state
  const errors = form.formState.errors;
  const state = form.watch();

  // Form state handler
  const onHandleState = React.useCallback(
    (
      key: keyof NewEventData,
      value: string | NewEventLink[] | NewEventType[] | boolean,
    ) => {
      form.setValue(key, value, {
        shouldValidate: true,
      });
    },
    [],
  );

  React.useEffect(() => {
    if (state.isFree) {
      form.reset({
        ...state,
        costPolitic: [
          {
            id: `${new Date()}`,
            name: "",
          },
        ],
      });
    }
  }, [state.isFree]);

  // Requests to api
  const { data: eventStatuses } = useEventStatuses();
  const uploadImage = useUploadImage();
  const updateEvent = useUpdateEvent();

  const [isLoading, setIsLoading] = React.useState(false);

  // Event status id state
  const [eventStatusId, setEventStatusId] = React.useState<
    string | undefined
  >();

  // Form submit handler
  const onSubmitHandler = async (data: NewEventData) => {
    if (!eventStatusId) return;
    const formData = new FormData();
    try {
      setIsLoading(true);
      const currentIsFreeAndCostPolitic = gerCurrentCostPolitic(
        form.getValues("costPolitic"),
      );
      const updatedEvent = await updateEvent.mutateAsync({
        ...data,
        id: event!.id,
        eventStatusId,
        eventsLink: data.eventsLink,
        status: "MODERATION",
        ...currentIsFreeAndCostPolitic,
      });
      if (
        !croppedCover &&
        event?.multimedia?.find((imageObg) => imageObg.type === "Cover")
      ) {
      }
      if (
        !croppedCoverDetils &&
        event?.multimedia?.find((imageObg) => imageObg.type === "Details")
      ) {
      }
      if (croppedCover) {
        // If cropped image exists
        formData.set("cover", croppedCover as Blob);
        await uploadImage.mutateAsync({
          id: updatedEvent.id,
          formData,
          type: "Cover",
        });
        formData.delete("cover");
      }
      // If croppedDetails image exists
      if (croppedCoverDetils) {
        formData.set("cover", croppedCoverDetils as Blob);
        await uploadImage.mutateAsync({
          id: updatedEvent.id,
          formData,
          type: "Details",
        });
        formData.delete("cover");
      }
      toast.success("Запись отправлена на модерацию");
      navigate(-1);
    } catch (e) {
      toast.error("Не удалось обновить запись");
      console.error(e);
      navigate(-1);
    } finally {
      setIsLoading(false);
    }
  };

  const publish = eventStatuses ? eventStatuses[0]?.id : "";
  const archive = eventStatuses ? eventStatuses[1]?.id : "";

  const { toggle, isShowing } = useModal();

  return (
    <>
      <LayoutAccount
        title="Детали мероприятия"
        isArrowGoBack={true}
        onClick={toggle}
      >
        <div
          className={styles.EventCreatePage}
          onSubmit={(e) => {
            e.preventDefault();
            //if custom errors success => update events
            form
              .getValues("costPolitic")
              .every(
                (value) =>
                  (value.name && value.price) || (!value.name && !value.price),
              ) && form.handleSubmit(onSubmitHandler)();
          }}
        >
          <form
            className={styles.EventCreatePage__form}
            id="event-update-form-id"
          >
            <Preloader isLoading={isLoadingData || isLoadingEvent}>
              <EventForm
                onHandleState={onHandleState}
                onUploadCover={handleUploadCover}
                onDeleteCover={handleDeleteCropped}
                onEditCover={handleEditCover}
                croppedCover={croppedCover}
                onUploadCoverDetails={handleUploadCoverDetils}
                onDeleteCoverDetails={handleDeleteCroppedDetils}
                onEditCoverDetails={handleEditCoverDetils}
                croppedCoverDetails={croppedCoverDetils}
                errors={errors}
                state={state}
              />
            </Preloader>
            <div className={styles.EventCreatePage__buttons}>
              <Button
                className={styles.EventCreatePage__button}
                onClick={() => setEventStatusId(publish)}
                fullWidth={true}
                disabled={isLoading}
                variant="contained_nuar"
                form="event-update-form-id"
                type="submit"
              >
                Опубликовать
              </Button>
              <BaseButton
                className={styles.EventCreatePage__button}
                onClick={() => setEventStatusId(archive)}
                variant="base-text-btn"
                disabled={isLoading}
                form="event-update-form-id"
                type="submit"
              >
                Сохранить в архив
              </BaseButton>
            </div>
          </form>
          <BaseImage
            className={styles.EventCreatePage__image}
            src={imageGopnikCreateEvent}
            height="516"
            width="407.15"
          />
        </div>
      </LayoutAccount>
      <Cropper
        isOpen={isOpenCropper}
        toggle={toggleCropper}
        file={uploadedCover as File}
        onChange={handleUploadCropped}
        onCancel={toggleCropper}
        type={"Cover"}
      />
      <Cropper
        isOpen={isOpenCropperDetils}
        toggle={toggleCropperDetils}
        file={uploadedCoverDetils as File}
        onChange={handleUploadCroppedDetils}
        onCancel={toggleCropperDetils}
        type={"Details"}
      />
      <CloseEventModal isOpen={isShowing} toggle={toggle} />
    </>
  );
};

export default EventUpdatePage;
