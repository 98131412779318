import { PropsWithChildren } from "react";
import "./styles.scss";

export function BaseLabel({
  className,
  children,
  label,
  htmlFor,
  ...rest
}: PropsWithChildren<{ label: string; className?: string; htmlFor?: string }>) {
  return (
    <label
      className={["base-label", className].join(" ").trim()}
      {...rest}
      htmlFor={htmlFor}
    >
      {label}
      {children}
    </label>
  );
}
