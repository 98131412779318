import { useNavigate } from "react-router-dom";

import { BaseTypography } from "src/components/base/BaseTypography";
import { BaseImage } from "src/components/base/BaseImage";

import svgArrowBack from "src/common/svg/svg-arrow-back.svg";
import svgArrowBackWhite from "src/common/svg/svg-arrow-back-white.svg";

import styles from "./GoBack.module.scss";
import clsx from "clsx";

type PropsType = {
  className?: string;
  title?: string;
  to?: string;
  onClick?: () => void;
  isWhite?: boolean;
};

const GoBack = ({
  className = "",
  to,
  title = "Назад",
  onClick,
  isWhite,
}: PropsType) => {
  const navigate = useNavigate();
  return (
    <div
      className={clsx({
        [styles.GoBack]: true,
        [className]: !!className,
      })}
      onClick={
        onClick ? () => onClick() : () => (to ? navigate(to) : navigate(-1))
      }
    >
      <BaseImage
        className={styles.GoBack__image}
        src={isWhite ? svgArrowBackWhite : svgArrowBack}
      />
      <BaseTypography
        className={clsx(styles.GoBack__text, {
          [styles["GoBack__text-white"]]: isWhite,
        })}
        variant="p"
      >
        {title}
      </BaseTypography>
    </div>
  );
};

export default GoBack;
