import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import clsx from "clsx";

import { BaseTypography } from "src/components/base/BaseTypography";
import { BaseImage } from "src/components/base/BaseImage";
import { BaseIcon } from "src/components/base/BaseIcon";
import { Tag } from "src/components/base/Tag";
import EventStatus from "./EventStatus";

import { RoutesEnum } from "src/common/enums";
import { useEventConditions } from "src/hooks/useEventConditions";
import { useDaysTillEvent } from "src/hooks/useDaysTillEvent";
import { formatDate } from "src/common/functions/formatDate";
import { NewEvent } from "src/api/hooks/eventsHooks";
import EventButtonSwitch from "./EventButtonSwitch";
import imageNotUploaded from "src/common/images/event-photo-not-found.webp";
import { formatEnding } from "src/common/functions/formatEnding";
import styles from "./EventPreview.module.scss";

type PropsType = NewEvent & {
  personIdFromJWT: string | undefined;
};

const EventPreview = (props: PropsType) => {
  const navigate = useNavigate();

  // Days till event
  const daysTillEvent = useDaysTillEvent(props?.meetingAt, [props]);

  // Event conditions
  const { isComplited, isOwner } = useEventConditions({ ...props });

  // The function navigate to event details
  const onGoToDetails = () =>
    navigate(RoutesEnum.EVENTS_UPDATE + "/" + props.titleAlias);

  const onGoEvents = () => navigate("events/" + props?.titleAlias);

  // The Short preview of the text
  const text: string =
    props.title.length >= 48
      ? props.title.slice(0, 48).trim() + "..."
      : props.title.trim();

  const onOpenTelegramModal = () => {
    if (!props.registrationLink) return;
    window.open(`${props.registrationLink}`);
  };

  const prices = props.costPolitic?.map((item) => item.price) || [];

  return (
    <Link to={"events/" + props?.titleAlias}>
      <section className={`${styles.EventPreview1} ${styles.EventPreview}`}>
        <div className={styles.EventPreview__header}>
          <BaseImage
            className={styles.EventPreview__preview}
            lazyLoading={true}
            src={
              props?.multimedia?.find((imageObg) => imageObg.type === "Cover")
                ?.path || imageNotUploaded
            }
          />
          <p
            className={clsx(styles.EventPreview__daysLeftTag, {
              [styles.EventPreview__daysLeftTag_tomorrow]:
                parseInt(formatEnding(daysTillEvent)) === 0,
              [styles.EventPreview__daysLeftTag_noneDisplay]:
                parseInt(formatEnding(daysTillEvent)) != 0 ||
                parseInt(formatEnding(daysTillEvent)) != 1,
            })}
          >
            {parseInt(formatEnding(daysTillEvent)) === 0
              ? "Сегодня"
              : parseInt(formatEnding(daysTillEvent)) === 1
              ? "Завтра"
              : undefined}
          </p>
          <div className={styles.EventPreview__tags}>
            {props.eventType?.map(({ name, id }) => (
              <Tag
                className={styles.EventPreview__tag}
                variant="event_preview"
                key={id}
              >
                {name}
              </Tag>
            ))}
          </div>
        </div>
        <div className={styles.EventPreview__body}>
          <BaseTypography className={styles.EventPreview__title} variant="h3">
            {text}
          </BaseTypography>
          <ul className={styles.EventPreview__list}>
            <li className={styles.EventPreview__item}>
              <BaseIcon
                className={styles.EventPreview__icon}
                viewBox="0 0 20 20"
                height="20"
                width="20"
                icon="CALENDAR"
              />
              <span className={styles.EventPreview__item_text}>
                {formatDate(props.meetingAt)}
                {" / "} {props.time}
              </span>
              <span className={styles.EventPreview__item_issue}>
                {daysTillEvent > 1
                  ? "Через " + formatEnding(daysTillEvent)
                  : daysTillEvent === 1
                  ? "Завтра"
                  : daysTillEvent === 0
                  ? "Сегодня"
                  : "Завершилось"}
              </span>
            </li>
            <li className={styles.EventPreview__item}>
              <BaseIcon
                className={styles.EventPreview__icon}
                viewBox="0 0 13 16"
                height="16"
                width="13"
                icon="POSITION"
              />
              <span className={styles.EventPreview__item_text}>
                {props.address}
              </span>
            </li>
            <li className={styles.EventPreview__item}>
              <BaseIcon
                className={styles.EventPreview__icon}
                viewBox="0 0 17 17"
                height="17"
                width="17"
                icon="ROUBLE"
              />
              <span className={styles.EventPreview__item_text}>
                {props.isFree || !prices.length ? (
                  "Бесплатно"
                ) : (
                  <>
                    {prices.length > 1
                      ? Math.min(...prices) + "-" + Math.max(...prices)
                      : prices[0] ?? "Бесплатно"}{" "}
                    <RubSvg />
                  </>
                )}
              </span>
            </li>
          </ul>
          <div className={styles.EventPreview__controls}>
            <EventStatus
              className={styles.EventPreview__status}
              isOwner={isOwner}
              status={
                props.status === "MODERATION"
                  ? "На проверке"
                  : props.eventStatus.name
              }
            />
            <EventButtonSwitch
              isComplited={isComplited}
              isOwner={isOwner}
              onGoToDetails={onGoToDetails}
              onOpenTelegramModal={onOpenTelegramModal}
              onGoEvents={onGoEvents}
              eventId={props.titleAlias}
              isPreview
              isHaveRegLink={!!props.registrationLink}
            />
          </div>
        </div>
      </section>
    </Link>
  );
};

export default EventPreview;

const RubSvg = () => {
  return (
    <svg
      width="9"
      height="11"
      viewBox="0 0 9 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.33452 6.34872H0V4.83239H5.29474C5.7223 4.83239 6.07363 4.76444 6.34872 4.62855C6.62713 4.49266 6.83262 4.30374 6.9652 4.06179C7.10109 3.81984 7.16738 3.53646 7.16406 3.21165C7.16738 2.89347 7.10109 2.60843 6.9652 2.35653C6.83262 2.10133 6.63045 1.90081 6.35866 1.75497C6.0902 1.60582 5.74882 1.53125 5.33452 1.53125H3.33594V10.1818H1.49645V0H5.33452C6.12334 0 6.79119 0.142519 7.33807 0.427557C7.88494 0.70928 8.29924 1.09209 8.58097 1.57599C8.866 2.05658 9.00852 2.59683 9.00852 3.19673C9.00852 3.81984 8.86435 4.36837 8.576 4.84233C8.28764 5.31297 7.87003 5.68253 7.32315 5.95099C6.77628 6.21615 6.1134 6.34872 5.33452 6.34872ZM5.56818 7.1044V8.62074H0V7.1044H5.56818Z"
        fill="#3D3D3D"
      />
    </svg>
  );
};
