import React from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import clsx from "clsx";

import { EventButtonSwitch } from "src/content/EventPreview";
import { BaseLoader } from "src/components/base/BaseLoader";
import { BaseImage } from "src/components/base/BaseImage";
import { BaseIcon } from "src/components/base/BaseIcon";
import { GoBack } from "src/content";
import { Tag } from "src/components/base/Tag";

import { useDaysTillEvent } from "src/hooks/useDaysTillEvent";
import { useEventConditions } from "src/hooks/useEventConditions";
import { formatDate } from "src/common/functions/formatDate";
import { QueriesEnum, RoutesEnum } from "src/common/enums";
import { useEvent } from "src/api/hooks/eventsHooks";
import { useEffectAfterMount } from "src/hooks/useEffectAfterMount";
import { formatEnding } from "src/common/functions/formatEnding";
import imageNotUploaded from "src/common/images/examp.jpg";
import styles from "../event/Event.module.scss";
import { useMe } from "src/api/hooks/personsHooks";
import ContentDropdown from "src/components/base/Dropdown/Dropdown";

export default function EventAdminPage() {
  const navigate = useNavigate();
  const { data: user } = useMe();
  const { id } = useParams();
  const { data, isLoading, isError } = useEvent({ id });

  // Days till event
  const daysTillEvent = useDaysTillEvent(data?.meetingAt, [data]);

  // Conditions
  const { isComplited, isOwner } = useEventConditions({
    ...data,
    personIdFromJWT: user?.id,
  } as any);

  const [isCopied, setIsCopied] = React.useState(false);

  useEffectAfterMount(() => {
    if (!isCopied) return;
    const timerId = setTimeout(() => {
      setIsCopied(false);
    }, 1500);
    return () => clearTimeout(timerId);
  }, [isCopied]);

  // Ref to text with telegram link
  const ref = React.createRef<HTMLDivElement>();

  const handleCopyLink = () => {
    if (!ref.current) return;
    navigator.clipboard.writeText(ref.current.dataset.link as string);
    setIsCopied(true);
  };

  if (isLoading) {
    return (
      <main className={styles.EventPageLoader}>
        <BaseLoader />
      </main>
    );
  }

  if (!data || isError) {
    return (
      <Navigate
        to={RoutesEnum.EVENTS}
        replace
        state={{
          path: location.pathname,
        }}
      />
    );
  }

  // The function navigate to event details
  const onGoToDetails = () => navigate(RoutesEnum.EVENTS_UPDATE + "/" + id);

  // The function open telegram chat
  const onOpenTelegramModal = () => {
    if (!data.registrationLink) return;
    window.open(`${data.registrationLink}`);
  };

  return (
    <>
      <main className={styles.EventPage}>
        <div className={styles.EventPage__topWrapper}>
          <div className={styles.EventPage__block_go_back}>
            <GoBack
              isWhite
              title="Все мероприятия"
              to={RoutesEnum.EVENTS + QueriesEnum.ALL_EVENTS}
            />
          </div>
          <div
            style={{
              position: "absolute",
              inset: 0,
              width: "100%",
              height: 500,
              zIndex: -1,
              background:
                "linear-gradient(180deg, rgba(0, 0, 0, 0.74) 0%, rgba(0, 0,  0, 0.3108) 100%)",
            }}
          />
          <BaseImage
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              height: 500,
              objectFit: "cover",
              objectPosition: "top",
              zIndex: -2,
            }}
            lazyLoading={true}
            src={
              data?.multimedia?.find((imageObg) => imageObg.type === "Details")
                ?.path ?? imageNotUploaded
            }
          />
          <div className={styles["EventPage__topWrapper-contentBlock"]}>
            <div className={styles.EventPage__text}>{data?.title}</div>

            <div className={styles.EventPage__tags}>
              {data?.eventType?.map(({ name, id }) => (
                <Tag
                  className={styles.EventPage__tag}
                  variant="event_details"
                  key={id}
                >
                  {name}
                </Tag>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.EventPage__ContentWrapper}>
          <h3 className={styles.EventPage__ContentWrapper_title}>Описание</h3>
          <ContentDropdown text={data.text} />
          <div className={styles.mobileParamsWrapper}>
            <h3 className={styles.EventPage__ContentWrapper_title}>
              Информация:
            </h3>
            <ul className={styles.EventPage__params}>
              <li className={styles.EventPage__param}>
                <BaseIcon
                  className={styles.EventPage__param_icon}
                  viewBox="0 0 34 34"
                  height="34"
                  width="34"
                  icon="CALENDAR_PURPLE"
                />
                <span className={styles.EventPage__param_text}>
                  {formatDate(data?.meetingAt)}
                  {" / "}
                  {data.time}
                </span>
                <span className={styles.EventPage__param_issue}>
                  {daysTillEvent > 1
                    ? "Через " + formatEnding(daysTillEvent)
                    : daysTillEvent === 1
                    ? "Завтра"
                    : daysTillEvent === 0
                    ? "Сегодня"
                    : "Завершилось"}
                </span>
              </li>

              <li className={styles.EventPage__param}>
                <BaseIcon
                  className={styles.EventPage__param_icon}
                  viewBox="0 0 34 34"
                  height="34"
                  width="34"
                  icon="POSITION_PINK"
                />
                <span className={styles.EventPage__param_text}>
                  {data?.address}
                </span>
              </li>

              <li className={styles.EventPage__param}>
                <BaseIcon
                  className={styles.EventPage__param_icon}
                  viewBox="0 0 34 34"
                  height="34"
                  width="34"
                  icon="FLAG_YELLOW"
                />
                <span className={styles.EventPage__param_text}>
                  Организатор: {data.organizer}
                </span>
              </li>
              {/* <li className={styles.EventPage__param}>
                <BaseIcon
                  className={styles.EventPage__param_icon}
                  viewBox="0 0 34 34"
                  height="34"
                  width="34"
                  icon="PC_GREEN"
                />
                <span className={styles.EventPage__param_text}>
                  {!data.online ? "Онлайн" : "Оффлайн"}
                </span>
              </li> */}
              <li className={styles.EventPage__param}>
                <BaseIcon
                  className={styles.EventPage__param_icon}
                  viewBox="0 0 34 34"
                  height="34"
                  width="34"
                  icon="ROUBLE_BLUE"
                />
                <span className={styles.EventPage__param_text}>
                  {data.isFree ? "Бесплатно" : "Платно"}
                </span>
              </li>
            </ul>

            <PriceList priceObj={data?.costPolitic} />
          </div>

          {(!!data.eventLink ||
            !!data.eventsLink.filter((event) => event.link && event.name)
              .length) && (
            <div className={styles.EventPage_LinksWrapper}>
              <div className={styles.EventPage_LinksWrapper__titleWrapper}>
                <h4 className={styles.EventPage__ContentWrapper_subTitle}>
                  Ссылки на мероприятие:
                </h4>
                {!!data.eventLink && (
                  <a
                    href={data.eventLink ?? "https://t.me/terkatalk"}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={clsx(
                      styles.EventPage_LinksWrapper__linkChat,
                      styles.EventPage_LinksWrapper__linkChat_w1024,
                    )}
                  >
                    Чат мероприятия
                    <ChatSvg />
                  </a>
                )}
              </div>
              <div className={styles.EventPage_LinksWrapper__linkElement}>
                <div className={styles.EventPage_LinksWrapper__linksBlock}>
                  {!!data.eventsLink.filter((event) => event.link && event.name)
                    .length ? (
                    data.eventsLink
                      .filter((event) => event.link && event.name)
                      .map(({ name, link }, index) => (
                        <div
                          key={index}
                          className={styles.EventPage_LinksWrapper__link}
                        >
                          <svg
                            width="16"
                            height="17"
                            viewBox="0 0 16 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.66406 4.49935H3.9974C3.26102 4.49935 2.66406 5.0963 2.66406 5.83268V12.4993C2.66406 13.2357 3.26102 13.8327 3.9974 13.8327H10.6641C11.4004 13.8327 11.9974 13.2357 11.9974 12.4993V9.83268M9.33073 3.16602H13.3307M13.3307 3.16602V7.16602M13.3307 3.16602L6.66406 9.83268"
                              stroke="black"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>

                          <a
                            href={link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {name}
                          </a>
                          {index <
                            data.eventsLink.filter(
                              (event) => event.link && event.name,
                            ).length -
                              1 && (
                            <span
                              className={styles.EventPage_LinksWrapper__slesh78}
                            >
                              /
                            </span>
                          )}
                        </div>
                      ))
                  ) : (
                    <p className={styles.EventPage_LinksWrapper__empty}>
                      Тут пусто ... других ссылок нет
                    </p>
                  )}
                </div>
                {!!data.eventLink && (
                  <a
                    href={data.eventLink ?? "https://t.me/terkatalk"}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.EventPage_LinksWrapper__linkChat}
                  >
                    Чат мероприятия
                    <ChatSvg />
                  </a>
                )}
              </div>
            </div>
          )}

          <div className={styles.EventPage__controls}>
            <EventButtonSwitch
              className={styles.EventPage__button}
              isComplited={isComplited}
              isOwner={isOwner}
              onGoToDetails={onGoToDetails}
              onOpenTelegramModal={onOpenTelegramModal}
              onGoEvents={() => {}}
              eventId={data.titleAlias}
              isHaveRegLink={!!data.registrationLink}
              isPreview={false}
            />
            <div
              className={styles.EventPage__link}
              onClick={handleCopyLink}
              data-link={location.href}
              ref={ref}
            >
              Поделиться мероприятием
              {isCopied && (
                <ToolType className={styles.EventPage__tooltype}>
                  Ссылка на мероприятие
                  <br /> скопирована
                </ToolType>
              )}
              <BaseIcon
                className={styles.EventPage__chain_icon}
                viewBox="0 0 20 20"
                height="20"
                width="20"
                icon="CHAIN"
              />
            </div>
          </div>
        </div>
        <div className={styles.laptopParamsWrapper}>
          <h3 className={styles.EventPage__ContentWrapper_title}>
            Информация:
          </h3>
          <ul className={styles.EventPage__params}>
            <li className={styles.EventPage__param}>
              <BaseIcon
                className={styles.EventPage__param_icon}
                viewBox="0 0 34 34"
                height="34"
                width="34"
                icon="CALENDAR_PURPLE"
              />
              <span className={styles.EventPage__param_text}>
                {formatDate(data?.meetingAt)}
                {" / "}
                {data.time}
              </span>
              <span className={styles.EventPage__param_issue}>
                {daysTillEvent > 1
                  ? "Через " + formatEnding(daysTillEvent)
                  : daysTillEvent === 1
                  ? "Завтра"
                  : daysTillEvent === 0
                  ? "Сегодня"
                  : "Завершилось"}
              </span>
            </li>

            <li className={styles.EventPage__param}>
              <BaseIcon
                className={styles.EventPage__param_icon}
                viewBox="0 0 34 34"
                height="34"
                width="34"
                icon="POSITION_PINK"
              />
              <span className={styles.EventPage__param_text}>
                {data?.address}
              </span>
            </li>

            <li className={styles.EventPage__param}>
              <BaseIcon
                className={styles.EventPage__param_icon}
                viewBox="0 0 34 34"
                height="34"
                width="34"
                icon="FLAG_YELLOW"
              />
              <span className={styles.EventPage__param_text}>
                Организатор: {data.organizer}
              </span>
            </li>
            {/* <li className={styles.EventPage__param}>
              <BaseIcon
                className={styles.EventPage__param_icon}
                viewBox="0 0 34 34"
                height="34"
                width="34"
                icon="PC_GREEN"
              />
              <span className={styles.EventPage__param_text}>
                {data.online ? "Онлайн" : "Оффлайн"}{" "}
              </span>
            </li> */}
            <li className={styles.EventPage__param}>
              <BaseIcon
                className={styles.EventPage__param_icon}
                viewBox="0 0 34 34"
                height="34"
                width="34"
                icon="ROUBLE_BLUE"
              />
              <span className={styles.EventPage__param_text}>
                {data.isFree ? "Бесплатно" : "Платно"}
              </span>
            </li>
          </ul>
          <PriceList priceObj={data?.costPolitic} />
        </div>
      </main>
    </>
  );
}

type ToolTypePropsType = {
  className?: string;
  children: React.ReactNode;
};

const ToolType = ({ className = "", children }: ToolTypePropsType) => {
  return (
    <div
      className={clsx({
        [styles.ToolType]: true,
        [className]: !!className,
      })}
    >
      {children}
    </div>
  );
};

const ChatSvg = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.25 4.5C2.25 3.67157 2.92157 3 3.75 3H14.25C15.0784 3 15.75 3.67157 15.75 4.5V10.5C15.75 11.3284 15.0784 12 14.25 12H10.5L6.75 15.75V12H3.75C2.92157 12 2.25 11.3284 2.25 10.5V4.5Z"
        fill="#C5E0FF"
      />
      <path
        d="M6 7.5H6.0075M9 7.5H9.0075M12 7.5H12.0075M6.75 12H3.75C2.92157 12 2.25 11.3284 2.25 10.5V4.5C2.25 3.67157 2.92157 3 3.75 3H14.25C15.0784 3 15.75 3.67157 15.75 4.5V10.5C15.75 11.3284 15.0784 12 14.25 12H10.5L6.75 15.75V12Z"
        stroke="#3D3D3D"
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const RubSvg = () => {
  return (
    <svg
      width="9"
      height="11"
      viewBox="0 0 9 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.33452 6.34872H0V4.83239H5.29474C5.7223 4.83239 6.07363 4.76444 6.34872 4.62855C6.62713 4.49266 6.83262 4.30374 6.9652 4.06179C7.10109 3.81984 7.16738 3.53646 7.16406 3.21165C7.16738 2.89347 7.10109 2.60843 6.9652 2.35653C6.83262 2.10133 6.63045 1.90081 6.35866 1.75497C6.0902 1.60582 5.74882 1.53125 5.33452 1.53125H3.33594V10.1818H1.49645V0H5.33452C6.12334 0 6.79119 0.142519 7.33807 0.427557C7.88494 0.70928 8.29924 1.09209 8.58097 1.57599C8.866 2.05658 9.00852 2.59683 9.00852 3.19673C9.00852 3.81984 8.86435 4.36837 8.576 4.84233C8.28764 5.31297 7.87003 5.68253 7.32315 5.95099C6.77628 6.21615 6.1134 6.34872 5.33452 6.34872ZM5.56818 7.1044V8.62074H0V7.1044H5.56818Z"
        fill="#3D3D3D"
      />
    </svg>
  );
};

const PriceList = ({
  priceObj,
}: {
  priceObj:
    | {
        id: string;
        name: string;
        price: number;
      }[]
    | undefined;
}) => {
  return (
    <ul className={styles.EventPage__priceList}>
      {priceObj?.map((cost) => {
        return (
          <li
            className={styles.EventPage__priceItem}
            key={cost.price + cost.id + cost.name}
          >
            <hr className={styles.EventPage__priceItem__line} />
            <p className={styles.EventPage__priceItem__name}>{cost.name}</p>
            <div className={styles.EventPage__priceItem__price}>
              {cost.price} <RubSvg />
            </div>
          </li>
        );
      })}
    </ul>
  );
};
